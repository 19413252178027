/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';

import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useNavigate } from 'react-router-dom';
import { FlexButtonWrapper } from './Purchase.styles';
import { resetStateAddPayment } from './AddPayment';
import { resetStateAddTrack } from './MultiAsset';
import { resetStateSelectPackage } from './SelectPackage';
import { trackEvent } from '../../utils/segmentService';

interface SidebarProps {
  show: boolean;
  setShowLeave: (value: boolean) => void;
  navigateTo: string;
}

export const PurchaseLeaveModal = ({
  show,
  setShowLeave,
  navigateTo,
}: SidebarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      trackEvent('Modal View', { modal_type: 'purchase_leave_modal' });
    }
  }, [show]);

  // Handling leave purchase flow
  const handleLeaveModal = () => {
    dispatch(resetStateAddPayment());
    dispatch(resetStateAddTrack());
    dispatch(resetStateSelectPackage());
    setShowLeave(false);
    navigate(navigateTo);
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => setShowLeave(false)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <div
        className="heading"
        style={{
          fontSize: '24px',
          fontWeight: '700',
          lineHeight: '33px',
          color: theme.colors.neutralW20,
          marginBottom: '8px',
        }}
      >
        <div>{t('PURCHASE_LEAVE_FLOW_MODAL_TITLE')}</div>
      </div>

      <p
        style={{
          fontSize: '14px',
          lineHeight: '19px',
          color: theme.colors.neutralW20,
          marginBottom: '32px',
        }}
      >
        {t('PURCHASE_LEAVE_FLOW_MODAL_DESC')}
      </p>

      <div
        className="footer"
        style={{
          color: 'black',
        }}
      >
        <FlexButtonWrapper>
          <Button
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={handleLeaveModal}
            style={{
              width: 'fit-content',
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('PURCHASE_LEAVE_FLOW_MODAL_BUTTON')}
          </Button>
          <Button
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={() => setShowLeave(false)}
            style={{
              width: 'fit-content',
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('PURCHASE_LEAVE_FLOW_MODAL_STAY_BUTTON')}
          </Button>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
