import { createSlice } from '@reduxjs/toolkit';
import { getHomeItems } from './Home.thunks';

export interface Info {
  items: [];
}

const HomeSlice = createSlice({
  name: 'Home',
  initialState: {
    info: {} as Info,
    isFetching: false,
    isSideMenuExpanded: true,
  },
  extraReducers: (builder) =>
    builder
      .addCase(getHomeItems.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getHomeItems.fulfilled, (state, { payload }) => {
        state.info = payload;
        state.isFetching = false;
      }),
  reducers: {
    setMenuState: (state, action) => {
      return {
        ...state,
        isSideMenuExpanded: action.payload,
      };
    },
  },
});

export const { setMenuState } = HomeSlice.actions;
export default HomeSlice.reducer;
