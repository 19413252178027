import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { SidebarNavItem } from '@dsny/dsny-component-library';
import { SidebarFooterWrapper } from './Sidebar.styles';

type SidebarFooterProp = {
  isExpanded: boolean;
  onClick: () => void;
};

const SidebarFooter = ({ isExpanded, onClick }: SidebarFooterProp) => {
  const { t } = useTranslation();
  return (
    <SidebarFooterWrapper>
      <SidebarNavItem
        as={NavLink}
        url="#"
        tabIndex={0}
        onClick={onClick}
        iconName={isExpanded ? 'Collapse' : 'Expand'}
        label={t(isExpanded ? 'Collapse' : 'Expand')}
        isExpanded={isExpanded}
        setting="mtr"
        mode="desktop"
        style={{
          textDecoration: 'none',
        }}
      />
    </SidebarFooterWrapper>
  );
};

export default SidebarFooter;
