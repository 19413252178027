import styled from 'styled-components';
import { device, mtrStyle } from 'src/styles/global';
import theme from 'src/styles/theme';

export interface Props {
  $isLoaded?: boolean;
}

export const MultiAssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1071px;

  @media ${device.mobile} {
    max-width: 288px;
  }

  @media ${device.tablet} {
    width: 90%;
  }

  @media ${device.ipad} {
    width: 90%;
  }
`;

export const MultiAssetBox = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${theme.colors.neutralW80};
  border-radius: ${mtrStyle.borderRadius};
  margin-bottom: 24px;

  @media ${device.mobile} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.ipad} {
    flex-direction: column;
  }
`;

export const ContainerLeft = styled.div`
  width: 42%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.colors.neutralW80};

  @media ${device.mobile} {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${theme.colors.neutralW80};
  }

  @media ${device.tablet} {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${theme.colors.neutralW80};
  }

  @media ${device.ipad} {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${theme.colors.neutralW80};
  }
`;

export const ContainerRight = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.ipad} {
    width: 100%;
  }
`;

export const UploaderEmptyState = styled.div`
  padding-bottom: 24px;
`;

export const UploaderProcessState = styled.div`
  border-top: 1px solid ${theme.colors.neutralW80};
  padding: 24px;

  @media ${device.mobile} {
    padding: 24px 16px;
  }
`;
