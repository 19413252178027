import React from 'react';
import { Navigate } from 'react-router-dom';
import FooterPrivate from 'src/components/Footer/FooterPrivate';
import { isAuthenticated } from 'src/features/Auth';
import {
  PageContent,
  SidebarContent,
} from 'src/components/Navbar/Sidebar/Sidebar.styles';
import Sidebar from 'src/components/Navbar/Sidebar/Sidebar';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import Navbar from '../components/Navbar/Navbar';

export interface Props {
  component: React.FC;
}

const PrivateRoute = ({ component: Component }: Props) => {
  const { isSideMenuExpanded } = useSelector((state: RootState) => state.home);

  return isAuthenticated() ? (
    <SidebarContent>
      <Sidebar />
      <PageContent className={isSideMenuExpanded ? 'blurred' : ''}>
        <Navbar />
        <Component />
        <FooterPrivate />
      </PageContent>
    </SidebarContent>
  ) : (
    <Navigate to="/auth/signin" />
  );
};

export default PrivateRoute;
