import React from 'react';

import {
  LogoMTRFullWhite,
  FaviconMTRWhite,
} from '@dsny/dsny-component-library';
import { LogoWrapper, NavLinkWrapper } from './Sidebar.styles';

type SidebarFooterProp = {
  to: string;
  isExpanded: boolean;
  onClick: () => void;
};

const SidebarLogo = ({ isExpanded, onClick }: SidebarFooterProp) => {
  return (
    <LogoWrapper $isExpanded={isExpanded}>
      <NavLinkWrapper tabIndex={0} to="" onClick={onClick}>
        {isExpanded && <LogoMTRFullWhite />}
        {!isExpanded && <FaviconMTRWhite />}
      </NavLinkWrapper>
    </LogoWrapper>
  );
};

export default SidebarLogo;
