import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/services/network';

interface PurchaseItems {
  artist: string;
  title: string;
  upload_s3_bucket: string;
  upload_s3_key: string;
  src_filename: string;
  track_id: number | undefined;
  start_date: string;
  version?: string;
}

export interface PurchaseCheckoutList {
  identifier: string;
  plan_id: string;
  release_id: string;
  promo_code: string;
  items: PurchaseItems[];
  payment_method?: {
    is_new: boolean;
    id: string;
    save_for_later: boolean;
  };
}

export interface PurchaseExtensionRenewal {
  identifier: string;
  company_id: string;
  song_id: string;
  plan_id: string;
  payment_method?: {
    is_new: boolean;
    id: string;
    save_for_later: boolean;
  };
}

export interface DeleteSavedCardRequest {
  identifier: string;
  payment_method_id: string;
}

export const getCurrentPaymentMethod = createAsyncThunk(
  'CURRENT_PAYMENT_METHOD',
  async (includeTransient: boolean) => {
    return axios
      .get(
        `/subscriptions/mtr/payment-method/current?include_transient=${includeTransient}`
      )
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

export const getCurrentPaymentMethodSetup = createAsyncThunk(
  'CURRENT_PAYMENT_METHOD_SETUP',
  async () => {
    return axios
      .get(`/billing/payment_method/setup`)
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

export const postPurchasePrepare = createAsyncThunk(
  'POST_PURCHASE_PREPARE',
  async () => {
    return axios
      .post('/subscriptions/mtr/purchase/prepare')
      .then((response) => response.data)
      .then((data) => {
        return data;
      });
  }
);

export const getPurchaseCheckout = createAsyncThunk(
  'GET_PURCHASE_CHECKOUT',
  async (purchaseCheckout: PurchaseCheckoutList) => {
    return axios
      .post('/subscriptions/mtr/purchase/checkout/all', purchaseCheckout)
      .then((response) => response)
      .then((data) => {
        return data;
      });
  }
);

export const getPurchaseRenewal = createAsyncThunk(
  'GET_PURCHASE_RENEWAL',
  async (purchaseRenewal: PurchaseExtensionRenewal) => {
    return axios
      .post('/subscriptions/mtr/purchase/renewal', purchaseRenewal)
      .then((response) => response)
      .then((data) => {
        return data;
      });
  }
);

export const getPurchaseExtension = createAsyncThunk(
  'GET_PURCHASE_EXTENSION',
  async (purchaseExtension: PurchaseExtensionRenewal) => {
    return axios
      .post('/subscriptions/mtr/purchase/extension', purchaseExtension)
      .then((response) => response)
      .then((data) => {
        return data;
      });
  }
);

export const deleteSavedCard = createAsyncThunk(
  'DELETE_SAVED_CARD',
  async (deleteSavedCardRequest: DeleteSavedCardRequest) => {
    return axios
      .delete('/subscriptions/mtr/payment-method', {
        data: deleteSavedCardRequest,
      })
      .then((response) => response)
      .then((data) => {
        return data;
      });
  }
);

export const getTeamMembers = createAsyncThunk('GET_TEAM_MEMBERS', async () => {
  return axios
    .get('/subscriptions/mtr/team/members')
    .then((response) => response)
    .then((data) => {
      return data;
    });
});
