import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import {
  getCurrentPaymentMethod,
  postPurchasePrepare,
  getPurchaseCheckout,
  getPurchaseRenewal,
  getPurchaseExtension,
  deleteSavedCard,
  getTeamMembers,
  getCurrentPaymentMethodSetup,
} from './AddPayment.thunks';

export interface CurrentPaymentMethodObj {
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
}

export interface BillingManagers {
  email: string;
  firstname: string;
  lastname: string;
  staffid: number;
}

export interface AddPaymentObj {
  currentPaymentMethod: CurrentPaymentMethodObj;
  billingManagers: BillingManagers[];
  paymentPrepare: PaymentPrepare;
  purchaseCheckout: PurchaseCheckoutStatus;
  cardManagement: CardManagement;
  teamMember: TeamMember;
  isFetching: boolean;
  hasError: boolean;
  hasPaymentError: boolean;
}

export interface Member {
  company_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: 'member' | 'admin';
  staff_id: number;
}

export interface TeamMemberData {
  company_name: string;
  company_id: string;
  members: Member[];
}

export interface TeamMember {
  teamMemberData: TeamMemberData;
  memberStatus: MemberStatus;
}

export interface CardManagement {
  shouldSaveCard: boolean;
  isCardNew: boolean;
}

export interface PaymentPrepare {
  clientSecret?: string;
}

export interface PurchaseCheckoutStatus {
  isPurchaseSuccessful: boolean;
}

const PAYMENT_ERROR_CODE = 'VALIDATION_INVALID_PAYMENT_METHOD';

export enum MemberStatus {
  STAND_ALONE_MEMBER = 'STAND_ALONE_MEMBER', // Scenario A - user without company
  COMPANY_MEMBER = 'COMPANY_MEMBER', // Scenario B - user within a company
  TEAM_MEMBER = 'TEAM_MEMBER', // Scenario C - team space
}
const AddPaymentSlice = createSlice({
  name: 'AddPayment',
  initialState: {
    currentPaymentMethod: {},
    billingManagers: [{}],
    paymentPrepare: {},
    purchaseCheckout: { isPurchaseSuccessful: false },
    cardManagement: { shouldSaveCard: false, isCardNew: false },
    teamMember: {},
    isFetching: false,
    hasError: false,
    hasPaymentError: false,
  } as AddPaymentObj,
  extraReducers: (builder) =>
    builder
      .addCase(getCurrentPaymentMethod.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCurrentPaymentMethod.fulfilled, (state, { payload }) => {
        state.currentPaymentMethod = payload.data.current_payment_method;
        state.billingManagers = payload.data.billing_managers;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getCurrentPaymentMethod.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get current payment method')
        );
      })
      .addCase(getCurrentPaymentMethodSetup.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCurrentPaymentMethodSetup.fulfilled, (state, { payload }) => {
        state.paymentPrepare.clientSecret = payload.data.client_secret;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getCurrentPaymentMethodSetup.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get current payment method setup')
        );
      })
      .addCase(postPurchasePrepare.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(postPurchasePrepare.fulfilled, (state, { payload }) => {
        state.paymentPrepare.clientSecret = payload.data?.client_secret;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(postPurchasePrepare.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to purchase prepare')
        );
      })
      .addCase(getPurchaseCheckout.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPurchaseCheckout.fulfilled, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = true;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getPurchaseCheckout.rejected, (state, action) => {
        state.isFetching = false;
        state.hasError = true;
        state.hasPaymentError = action.error.code === PAYMENT_ERROR_CODE;
        state.purchaseCheckout.isPurchaseSuccessful = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get purchase checkout')
        );
      })
      .addCase(getPurchaseRenewal.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPurchaseRenewal.fulfilled, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = true;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getPurchaseRenewal.rejected, (state, action) => {
        state.purchaseCheckout.isPurchaseSuccessful = false;
        state.hasError = true;
        state.isFetching = false;
        state.hasPaymentError = action.error.code === PAYMENT_ERROR_CODE;
        Sentry.captureException(
          formatTextForSentryLog('failed to get purchase renewal')
        );
      })
      .addCase(getPurchaseExtension.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPurchaseExtension.fulfilled, (state) => {
        state.purchaseCheckout.isPurchaseSuccessful = true;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getPurchaseExtension.rejected, (state, action) => {
        state.purchaseCheckout.isPurchaseSuccessful = false;
        state.hasError = true;
        state.isFetching = false;
        state.hasPaymentError = action.error.code === PAYMENT_ERROR_CODE;
        Sentry.captureException(
          formatTextForSentryLog('failed to get purchase extension')
        );
      })
      .addCase(deleteSavedCard.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSavedCard.fulfilled, (state) => {
        return {
          currentPaymentMethod: {},
          billingManagers: state.billingManagers,
          paymentPrepare: {},
          purchaseCheckout: { isPurchaseSuccessful: false },
          cardManagement: { shouldSaveCard: false, isCardNew: true },
          teamMember: state.teamMember,
          isFetching: false,
          hasError: false,
          hasPaymentError: false,
        } as AddPaymentObj;
      })
      .addCase(deleteSavedCard.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to delete saved card')
        );
      })
      .addCase(getTeamMembers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getTeamMembers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        const teamMemberData = payload.data.data;

        // Checking team scenarios
        let status = MemberStatus.STAND_ALONE_MEMBER;
        if (
          teamMemberData.members?.length === 1 &&
          teamMemberData.company_name !== ''
        ) {
          status = MemberStatus.COMPANY_MEMBER;
        } else if (teamMemberData.members?.length > 1) {
          status = MemberStatus.TEAM_MEMBER;
        }

        state.teamMember = {
          teamMemberData,
          memberStatus: status,
        };
      })
      .addCase(getTeamMembers.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get team members')
        );
      }),
  reducers: {
    resetStateAddPayment: () => {
      return {
        currentPaymentMethod: {},
        billingManagers: [{}],
        paymentPrepare: {},
        purchaseCheckout: { isPurchaseSuccessful: false },
        cardManagement: { shouldSaveCard: false, isCardNew: false },
        teamMember: {},
        isFetching: false,
        hasError: false,
        hasPaymentError: false,
      } as AddPaymentObj;
    },
    updateSaveCardFlag: (state, action) => {
      state.cardManagement.shouldSaveCard = action.payload;
    },
    setIsCardNew: (state, action) => {
      state.cardManagement.isCardNew = action.payload;
    },
    resetPaymentPrepate: (state) => {
      state.paymentPrepare.clientSecret = undefined;
      state.hasError = false;
    },
  },
});

export const {
  resetStateAddPayment,
  updateSaveCardFlag,
  resetPaymentPrepate,
  setIsCardNew,
} = AddPaymentSlice.actions;

export default AddPaymentSlice.reducer;
