import React, { useEffect, useState } from 'react';
import { Collapsible, SidebarNavItem } from '@dsny/dsny-component-library';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { setMenuState } from 'src/features/Home';
import { PurchaseLeaveModal } from 'src/features/Purchase/PurchaseLeaveModal';
import {
  CustomNavItemStyles,
  CustomSidebarStyles,
  GradientOverlay,
  SidebarWrapper,
} from './Sidebar.styles';
import SidebarFooter from './SidebarFooter';
import SidebarLogo from './SidebarLogo';

// mobile width
const mobileWidthSize = 480;

const Sidebar: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSideMenuExpanded } = useSelector((state: RootState) => state.home);
  const [showModal, setShowModal] = useState(false);
  const [modalNavigateTo, setModalNavigateTo] = useState('');

  const toggleMenu = () => {
    dispatch(setMenuState(!isSideMenuExpanded));
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= mobileWidthSize;
    if (isMobile) {
      dispatch(setMenuState(false));
    }
  }, []);

  // Handling sidebar navigation
  const handleNavigation = (navigateTo: string, blockFrom: string) => {
    const showModalBeforeNavigating = location.pathname.includes(blockFrom);
    const isMobile = window.innerWidth <= mobileWidthSize;

    if (isMobile) {
      dispatch(setMenuState(false));
    }

    if (showModalBeforeNavigating) {
      setShowModal(true);
      setModalNavigateTo(navigateTo);
    } else {
      setTimeout(() => {
        navigate(navigateTo);
      });
    }
  };

  return (
    <>
      <SidebarWrapper className={isSideMenuExpanded ? 'expanded' : ''}>
        <Collapsible
          width={240}
          isExpanded={isSideMenuExpanded}
          style={{ ...CustomSidebarStyles }}
        >
          <div>
            <SidebarLogo
              to="/dashboard"
              isExpanded={isSideMenuExpanded}
              onClick={() => handleNavigation(`/dashboard`, '/payment')}
            />
            <SidebarNavItem
              as={NavLink}
              url=""
              tabIndex={0}
              iconName="Home"
              label="Dashboard"
              isExpanded={isSideMenuExpanded}
              isActive={location.pathname === '/dashboard'}
              isDisabled={false}
              isBackgroundTransparent={false}
              setting="mtr"
              mode="desktop"
              style={{ ...CustomNavItemStyles }}
              onClick={() => handleNavigation(`/dashboard`, '/payment')}
            />
          </div>

          <SidebarFooter
            isExpanded={isSideMenuExpanded}
            onClick={() => toggleMenu()}
          />
        </Collapsible>
      </SidebarWrapper>

      {/* Gradient Overlay */}
      <GradientOverlay
        className={isSideMenuExpanded ? 'active' : ''}
        onClick={() => toggleMenu()}
      />
      <PurchaseLeaveModal
        show={showModal}
        setShowLeave={setShowModal}
        navigateTo={modalNavigateTo}
      />
    </>
  );
};

export default Sidebar;
