import { MTRFullLogo, Text } from '@dsny/dsny-component-library';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuthState,
  getAvatarInfo,
  getSessionUser,
  logout,
} from 'src/features/Auth';
import RadioStationModal from 'src/features/RadioStationModal';
import theme from 'src/styles/theme';
import {
  Attachmoney,
  GroupsUnfilled,
  Sell,
  Logout,
  Keyboardarrowup,
  Keyboardarrowdown,
  Radio,
  Settings,
} from '@dsny/dsny-component-library/icons';
import PromoPriceModal from 'src/features/PromoPriceModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import PricingModal from 'src/features/PricingModal';
import {
  AddPaymentObj,
  getTeamMembers,
  MemberStatus,
} from 'src/features/Purchase/AddPayment';
import { formatEmailToShort } from 'src/utils/Formatter';
import { setMenuState } from 'src/features/Home';
import { useNavigate } from 'react-router-dom';
import { getPromotions } from 'src/features/Dashboard';
import {
  NavContainer,
  Menu,
  MenuItem,
  PromoPriceButton,
  PricingButton,
  TeamSpaceButton,
  SignoutButtonMobile,
  AccountNameWrapper,
  TeamSpaceDropdownMobile,
  TeamSpaceDescMobile,
  AccountBadgeMobileWrapper,
  AccountInfoWrapper,
  AccountNameEmailWrapper,
  StationListButtonMobile,
  IconWrapper,
  SidebarMobile,
  LogoWrapperMobile,
  LogoWrapperDesktop,
  PreferencesButtonMobile,
} from './Navbar.styles';
import { resetIdentifyUser, trackEvent } from '../../utils/segmentService';
import AccountDropdown from './AccountDropdown/AccountDropdown';
import AccountBadges from './AccountBadges/AccountBadges';

const SHOWED_PROMO_MODAL = 'SHOWED_PROMO_MODAL';

const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showStationModal, setShowStationModal] = useState(false);
  const [showPromoPriceModal, setShowPromoPriceModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [isTeamSpaceVisibleMobile, setTeamSpaceVisibleMobile] = useState(false);
  const { userInfo }: AuthState = useSelector((state: RootState) => state.auth);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) dispatch(getAvatarInfo());
  }, [userInfo]);

  const { promotionData } = useSelector(
    (state: RootState) => state.dashboard.promotion
  );

  useEffect(() => {
    if (!promotionData) {
      dispatch(getPromotions());
    }
  }, []);

  const { teamMember }: AddPaymentObj = useSelector(
    (state: RootState) => state.addPayment
  );

  // Getting session user fullname and email
  const { fullname, email } = getSessionUser();

  // Show Promo Price modal and update local storage
  useEffect(() => {
    if (promotionData) {
      const showPromoModal = JSON.parse(
        localStorage.getItem(SHOWED_PROMO_MODAL) || '[]'
      ) as string[];

      const { title } = promotionData;

      const isTitlePresent = showPromoModal.includes(title);

      if (!isTitlePresent) {
        showPromoModal.push(title);

        localStorage.setItem(
          SHOWED_PROMO_MODAL,
          JSON.stringify(showPromoModal)
        );

        setShowPromoPriceModal(true);
      }
    }
  }, [promotionData]);

  useEffect(() => {
    const { display } = getSessionUser();
    if (display && !teamMember?.memberStatus) {
      dispatch(getTeamMembers());
    }
  }, [teamMember]);

  // Handling signout
  const signOut = async () => {
    const isUserLoggedOut = await logout();
    if (isUserLoggedOut) {
      resetIdentifyUser();
      trackEvent('Logout', {}, () => {
        window.location.reload();
      });
    }
  };

  // Handling team space toggle mobile
  const teamSpaceToggleMobile = () => {
    setTeamSpaceVisibleMobile((prev) => !prev);
  };

  // Close the menu if the click is outside the menu component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    // Attach the click event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  const openSideMenu = () => {
    dispatch(setMenuState(true));
  };

  // Handling navbar navigation
  const handleNavigation = () => {
    setIsOpen(false);
    navigate('/billing');
  };

  return (
    <NavContainer ref={componentRef} isOpen={isOpen}>
      {/* Sidebar - mobile */}
      <SidebarMobile onClick={() => openSideMenu()}>
        <span />
        <span />
        <span />
      </SidebarMobile>
      {/* Logo */}
      <LogoWrapperMobile>
        <MTRFullLogo />
      </LogoWrapperMobile>
      <LogoWrapperDesktop>
        <div />
      </LogoWrapperDesktop>
      {/* Account badge button - mobile */}
      <AccountBadgeMobileWrapper>
        <AccountBadges
          avatarColor={userInfo?.color || ''}
          avatarInitals={userInfo?.initials || ''}
          onClick={() => setIsOpen(!isOpen)}
        />
      </AccountBadgeMobileWrapper>

      <Menu isOpen={isOpen}>
        <MenuItem>
          {/* Account info - mobile */}
          <AccountInfoWrapper>
            {/* User fullname and email */}
            <AccountNameEmailWrapper>
              <AccountBadges
                avatarColor={userInfo?.color || ''}
                avatarInitals={userInfo?.initials || ''}
              />
              <AccountNameWrapper>
                <Text ellipsis maxWidth="216px" color={theme.colors.neutralW40}>
                  {fullname}
                </Text>
                <Text
                  fontSize="12px"
                  ellipsis
                  maxWidth="216px"
                  color={theme.colors.neutralW40}
                >
                  {formatEmailToShort(email)}
                </Text>
              </AccountNameWrapper>
            </AccountNameEmailWrapper>

            {/* Team space */}
            {teamMember?.memberStatus === MemberStatus.TEAM_MEMBER && (
              <>
                <TeamSpaceDropdownMobile onClick={teamSpaceToggleMobile}>
                  <TeamSpaceButton>
                    <GroupsUnfilled width={24} height={24} display="flex" />
                    {t('TEAM_SPACE_NAV_CTA')}
                  </TeamSpaceButton>
                  {isTeamSpaceVisibleMobile ? (
                    <Keyboardarrowup />
                  ) : (
                    <Keyboardarrowdown />
                  )}
                </TeamSpaceDropdownMobile>
                <TeamSpaceDescMobile isOpen={isTeamSpaceVisibleMobile}>
                  {t('TEAM_SPACE_DESC')}
                </TeamSpaceDescMobile>
              </>
            )}
          </AccountInfoWrapper>

          {/* Promo Price modal button - mobile and desktop */}
          {!!promotionData && (
            <div>
              <PromoPriceButton onClick={() => setShowPromoPriceModal(true)}>
                <IconWrapper>
                  <Sell fill={theme.colors.mtr60} />
                </IconWrapper>
                {promotionData.name}
              </PromoPriceButton>
              <PromoPriceModal
                promotionData={promotionData}
                showPromoPriceModal={showPromoPriceModal}
                setShowPromoPriceModal={setShowPromoPriceModal}
              />
            </div>
          )}

          {/* Pricing modal button - mobile and desktop */}
          <div>
            <PricingButton onClick={() => setShowPricingModal(true)}>
              <IconWrapper>
                <Attachmoney />
              </IconWrapper>
              {t('PRICING_MODAL_NAV_CTA')}
            </PricingButton>
            <PricingModal
              showPricingModal={showPricingModal}
              setShowPricingModal={setShowPricingModal}
            />
          </div>

          {/* Station list modal button - mobile */}
          <div>
            <StationListButtonMobile onClick={() => setShowStationModal(true)}>
              <Radio />
              {t('NAVBAR_STATION_LIST_MODAL')}
            </StationListButtonMobile>
            <RadioStationModal
              showStationModal={showStationModal}
              setShowStationModal={setShowStationModal}
            />
          </div>

          {/* Billing preferences button - mobile */}
          <PreferencesButtonMobile onClick={() => handleNavigation()}>
            <Settings />
            {t('NAVBAR_PREFERENCES_BUTTON')}
          </PreferencesButtonMobile>

          {/* Signout button - mobile */}
          <SignoutButtonMobile onClick={signOut}>
            <Logout />
            {t('NAVBAR_SIGNOUT_CTA_BUTTON')}
          </SignoutButtonMobile>

          {/* Account dropdown - desktop */}
          <AccountDropdown userInfo={userInfo} />
        </MenuItem>
      </Menu>
    </NavContainer>
  );
};

export default Navbar;
