import styled from 'styled-components';
import theme from 'src/styles/theme';

interface Props {
  avatarColor: string;
}

export const AccountBadgeButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  width: 28px;
  height: 28px;
  padding: 8px;
  background-color: ${({ avatarColor }) => theme.colors[avatarColor]};
  color: ${theme.colors.neutralW10};
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
`;
