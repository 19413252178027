/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import theme from 'src/styles/theme';
import { Text } from '@dsny/dsny-component-library';
import { Trans, useTranslation } from 'react-i18next';
import { TextDescritionWrapper, Title } from './AddPayment.styles';
import { BillingManagers } from './AddPayment.slice';

interface Props {
  billingManagers: BillingManagers[];
}

const ManagerNotification: React.FC<Props> = ({ billingManagers }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(true);

  // Handling show more
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  // Handle display email list
  const displayEmailList = () => {
    if (billingManagers.length > 1) {
      const ellipses = billingManagers.length > 10 ? '...' : '';
      return showMore ? (
        <Trans
          i18nKey="BILLING_ONLY_MANAGER_MESSAGE_SHOW_MORE"
          values={{
            manager: billingManagers
              ?.slice(0, 1)
              .map((manager, index) =>
                index === 0 ? `${manager.email}` : ` ${manager.email}`
              ),
          }}
          components={{
            bold: <strong />,
            boldButton: (
              <strong
                onClick={() => handleShowMore()}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              />
            ),
          }}
        />
      ) : (
        <Trans
          i18nKey="BILLING_ONLY_MANAGER_MESSAGE_SHOW_LESS"
          values={{
            manager: `${billingManagers?.map((manager, index) =>
              index === 0 ? `${manager.email}` : ` ${manager.email}`
            )}${ellipses} `,
          }}
          components={{
            bold: <strong />,
            boldButton: (
              <strong
                onClick={() => handleShowMore()}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              />
            ),
          }}
        />
      );
    }

    return (
      <Trans
        i18nKey="BILLING_ONLY_MANAGER_MESSAGE"
        values={{
          manager: billingManagers
            ?.slice(0, 1)
            .map((manager, index) =>
              index === 0 ? `${manager.email}` : ` ${manager.email}`
            ),
        }}
        components={{
          bold: <strong />,
        }}
      />
    );
  };

  return (
    <>
      {/* Message displaying team manager email(s) for members without the billing manager role and no credit card saved */}
      {/* Title and description */}
      <Title>{t('ADD_PAYMENT_TITLE')}</Title>
      <TextDescritionWrapper>
        <Text
          fontSize="14px"
          color={theme.colors.neutralW40}
          style={{ display: 'block' }}
        >
          {displayEmailList()}
        </Text>
      </TextDescritionWrapper>
    </>
  );
};

export default ManagerNotification;
