/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultTheme } from 'styled-components';
// import component library's theme
import { DefaultTheme as libraryTheme } from '@dsny/dsny-component-library';

export type ThemeProp = {
  [key: string]: string | any;
};

declare module 'styled-components' {
  export interface DefaultTheme {
    [key: string]: ThemeProp;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { name, ...themes } = libraryTheme;
const theme: DefaultTheme = {
  ...themes,
  fonts: {
    ...themes.fonts,
    primary: 'Nunito Sans',
  },
  colors: {
    ...themes.colors,
    text: {
      heading: '#333',
      active: '#57B968',
      body: '#999',
    },
    active: '#57B968',
    black: '#232323',
    blackTwo: '#333333',
    blackThree: '#5a5a5a',
    blue: '#16496d',
    brownishGrey: '#666666',
    cta: '#16496D',
    darkGrey: '#1C1D1F',
    darkSlateBlue: '#24496A',
    darkYellow: '#FFE522',
    error: '#CE1919',
    green: '#57B968',
    grey: '#eeeeee',
    greyTwo: '#bebebe',
    greyThree: '#999',
    greyFour: '#787878',
    greyBackground: '#F8F8F8',
    greyishBrown: '#565656',
    spotlight: '#0093E9',
    indigoBlue: '#16496d',
    info: '#C3D0D9',
    lightBlue: '#f9FAFC',
    lightGrey: '#D0D0D0',
    lightGreyTwo: '#DDDDDD',
    line: '#909090',
    mediumBlue: '#2196F3',
    mediumLightGrey: '#9E9E9E',
    modalBorder: '#EAEDF4',
    paleGrey: '#E7ECF5',
    red: '#CF3636',
    success: '#57B968',
    warmGrey: '#919191',
    warning: '#FF9800',
    white: '#FFF',
    whiteGrey: '#eeeeee',
    hoverGrey: '#f6f6f6',
    yellow: '#FFF46A',
    svgStroke: '#404040',
    brownGrey: '#a0a0a0',
    link: '#0d6ccb',
    notification: '#721c24',
    red90: '#f5c6cb',

    // Tonality style guide, 2022-10
    black0: '#000000',
    white0: '#FFFFFF',

    neutralC10: '#181A1C',
    neutralC20: '#2A2C2E',
    neutralC30: '#4A4E52',
    neutralC40: '#6B6E76',
    neutralC50: '#8F939D',
    neutralC60: '#BBC1C9',
    neutralC70: '#CDD1D7',
    neutralC80: '#E3E6EA',
    neutralC90: '#EFF1F3',
    neutralC100: '#F8F9FA',

    neutralW10: '#141414',
    neutralW20: '#222222',
    neutralW30: '#393939',
    neutralW40: '#4F4E4E',
    neutralW50: '#717070',
    neutralW60: '#AAAAAA',
    neutralW70: '#C9C9C9',
    neutralW80: '#E0E0E0',
    neutralW90: '#EBEBEB',
    neutralW100: '#F5F5F5',

    success10: '#071A0A',
    success20: '#1C3B21',
    success30: '#155724',
    success40: '#29883F',
    success50: '#41A950',
    success60: '#57B968',
    success70: '#90D79C',
    success80: '#B9EBBF',
    success90: '#D5F7DD',
    success100: '#F6FFF8',

    alert10: '#221B06',
    alert20: '#4B3903',
    alert30: '#765905',
    alert40: '#AD8718',
    alert50: '#D4A827',
    alert60: '#F3C744',
    alert70: '#FBD872',
    alert80: '#FFE7A0',
    alert90: '#FFEFC0',
    alert100: '#FFF9E8',

    error10: '#140101',
    error20: '#3B0707',
    error30: '#630C0C',
    error40: '#8E1111',
    error50: '#AE0101',
    error60: '#CE1919',
    error70: '#E95D5D',
    error80: '#F6A5A5',
    error90: '#FCDDDD',
    error100: '#FFF3F3',

    interact10: '#021222',
    interact20: '#022345',
    interact30: '#003A74',
    interact40: '#29883F',
    interact50: '#0D6CCB',
    interact60: '#3285E0',
    interact70: '#69ABF1',
    interact80: '#9ACAFE',
    interact90: '#C6E1FF',
    interact100: '#EAF4FF',

    mtr10: '#090A34',
    mtr20: '#202158',
    mtr30: '#37397C',
    mtr40: '#4F50A0',
    mtr50: '#6668C4',
    mtr60: '#7D7FE8',
    mtr70: '#9799ED',
    mtr80: '#B1B2F1',
    mtr90: '#D8D9F8',
    mtr100: '#F2F2FD',
  },
  padding: {
    modalBodyPadding: '16px 32px 16px 32px',
  },
  background: {
    white: '#ffffff',
    grey: '#F8F9FA',
    notification: '#f8d7da',
  },
};

export default theme;
