import { MaterialIcon, SpinningIcon } from '@dsny/dsny-component-library';
import React, {
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import AddNewTrack from './AddNewTrack/AddNewTrack';
import CitiesTracking from './CitiesTracking/CitiesTracking';
import { resetStateDashBoard } from './Dashboard.slice';
import {
  AirplayWrapper,
  DashboardContainer,
  DashboardWrapper,
  LoadingWrapper,
  SecondHeaderWrapper,
  SelectedSongWrapper,
  StationLocationWrapper,
  TimelineWrapper,
  UniversalFilterWrapper,
  VerticalLine,
} from './Dashboard.styles';
import Overview from './Overview/Overview';
import SelectedSong from './SelectedSong/SelectedSong';
import SongSelection from './SongSelection/SongSelection';
import StationsTracking from './StationsTracking/StationsTracking';
import UniversalFilter from './UniversalFilter/UniversalFilter';
import ComparisonTimelineTracking from './TimelineTracking/ComparisonTimelineTracking';
import TimelineTracking from './TimelineTracking/TimelineTracking';
import { resetStateAddTrack } from '../Purchase/MultiAsset';

const LazyAirplayTracking = lazy(
  () => import('./AirplayTracking/AirplayTracking')
);
const LazyComparisonAirplayTracking = lazy(
  () => import('./AirplayTracking/ComparisonAirplayTracking')
);

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [hasComparisonOn, setComparisonOn] = useState(true);

  const {
    songSelection,
    songOverview,
    songDaily,
    songHeatmap,
    songCities,
    songStations,
    filterSelection,
  } = useSelector((state: RootState) => state.dashboard);

  const { isFetchingSongSelection } = songSelection;
  const { isFetchingSongOverview } = songOverview;
  const { isFetchingSongDaily } = songDaily;
  const { isFetchingSongHeatmap } = songHeatmap;
  const { isFetchingSongCities } = songCities;
  const { isFetchingSongStations } = songStations;
  const [isLoading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [wasLoadingRemoved, setWasLoadingRemoved] = useState(false);

  // Log to Sentry if Loader was removed by the timeout
  useEffect(() => {
    if (wasLoadingRemoved) {
      Sentry.captureException(
        formatTextForSentryLog(
          `Dashboard loader took more than 30 seconds to turn off 
              SongSelection: ${isFetchingSongSelection} SongOverview: ${isFetchingSongOverview} SongDaily: ${isFetchingSongDaily} 
              SongHeatmap: ${isFetchingSongHeatmap} SongCities: ${isFetchingSongCities} SongStations: ${isFetchingSongStations}`
        )
      );
    }
  }, [wasLoadingRemoved]);

  // Handling loader max time
  const handleLoaderMaxTime = () => {
    return setTimeout(() => {
      setLoading(false);
      setWasLoadingRemoved(true);
    }, 30000); // 30 seconds delay
  };

  useEffect(() => {
    if (
      !isLoading &&
      (isFetchingSongSelection ||
        isFetchingSongOverview ||
        isFetchingSongDaily ||
        isFetchingSongHeatmap ||
        isFetchingSongCities ||
        isFetchingSongStations)
    ) {
      setLoading(true);
      timeoutRef.current = handleLoaderMaxTime();
    }

    if (
      !isFetchingSongSelection &&
      !isFetchingSongOverview &&
      !isFetchingSongDaily &&
      !isFetchingSongHeatmap &&
      !isFetchingSongCities &&
      !isFetchingSongStations
    ) {
      setLoading(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }
  }, [
    isFetchingSongSelection,
    isFetchingSongOverview,
    isFetchingSongDaily,
    isFetchingSongHeatmap,
    isFetchingSongCities,
    isFetchingSongStations,
  ]);

  // Get available promotions
  useEffect(() => {
    dispatch(resetStateAddTrack());
  }, []);

  useEffect(() => {
    if (
      songSelection?.songs?.length === 0 &&
      songSelection?.expiredSongs?.length === 0
    ) {
      dispatch(resetStateDashBoard());
      navigate('/payment/multiasset');
    }
  }, [songSelection, filterSelection]);

  // Caching heatmap
  const MemoizedLazyAirplayTracking = useMemo(() => {
    return LazyAirplayTracking;
  }, []);
  const MemoizedLazyComparisonAirplayTracking = useMemo(
    () => LazyComparisonAirplayTracking,
    []
  );

  return (
    <DashboardWrapper>
      {/* Second header */}
      <SecondHeaderWrapper>
        {/* Song combobox */}
        <SongSelection />
        {/* Universal filter */}
        <UniversalFilterWrapper>
          <UniversalFilter
            hasComparisonOn={hasComparisonOn}
            setComparisonOn={setComparisonOn}
          />
          <VerticalLine hasComparisonOn={hasComparisonOn} />
          <AddNewTrack hasComparisonOn={hasComparisonOn} />
        </UniversalFilterWrapper>
      </SecondHeaderWrapper>

      {/* Dashboard container */}
      <DashboardContainer>
        {isLoading && (
          <LoadingWrapper>
            <SpinningIcon style={{ transformOrigin: 'center 47%' }}>
              <MaterialIcon name="Rotate" color={theme.colors.mtr60} />
            </SpinningIcon>
          </LoadingWrapper>
        )}

        {/* Song info and overview */}
        <SelectedSongWrapper>
          <SelectedSong />
          <Overview hasComparisonOn={hasComparisonOn} />
        </SelectedSongWrapper>

        {/* Line chart */}
        <TimelineWrapper>
          {hasComparisonOn ? (
            <ComparisonTimelineTracking />
          ) : (
            <TimelineTracking />
          )}
        </TimelineWrapper>

        {/* Stations and city list */}
        <StationLocationWrapper>
          <StationsTracking hasComparisonOn={hasComparisonOn} />
          <CitiesTracking hasComparisonOn={hasComparisonOn} />
        </StationLocationWrapper>

        {/* Heat-map chart */}
        <AirplayWrapper>
          <Suspense fallback={<div>...</div>}>
            {hasComparisonOn ? (
              <MemoizedLazyComparisonAirplayTracking />
            ) : (
              <MemoizedLazyAirplayTracking />
            )}
          </Suspense>
        </AirplayWrapper>
      </DashboardContainer>
    </DashboardWrapper>
  );
};

export default Dashboard;
