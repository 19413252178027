import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import AddPaymentWrapper, {
  getCurrentPaymentMethod,
  resetStateAddPayment,
} from '../Purchase/AddPayment';

const Billing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(resetStateAddPayment());
    dispatch(getCurrentPaymentMethod(false));
  }, []);

  return <AddPaymentWrapper />;
};

export default Billing;
