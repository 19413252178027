import styled from 'styled-components';
import { device } from 'src/styles/global';
import { NavLink } from 'react-router-dom';
import theme from 'src/styles/theme';

interface Props {
  $isExpanded: boolean;
}

export const SidebarContent = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: hidden;
`;

export const SidebarWrapper = styled.div`
  flex-shrink: 0;
  z-index: 998;
  @media ${device.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 240px;
    z-index: 1002;
    background-color: ${theme.colors.neutralC10};
    transition: transform 0.3s ease;
    transform: translateX(-100%);

    &.expanded {
      transform: translateX(0);
    }
  }
`;

export const PageContent = styled.div`
  flex: 1;
  overflow-y: auto;
  transition: margin-left 0.3s ease; /* Smooth adjustment with Sidebar */

  &.blurred {
    @media ${device.mobile} {
      filter: blur(6px); /* Applies the blur effect on mobile */
    }
  }
`;

export const CustomSidebarStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '240px',
  minWidth: '106px',
  color: theme.colors.neutralW100,
  backgroundColor: theme.colors.neutralC10,
  padding: '40px 24px',
  gap: '8px',
};

export const CustomNavItemStyles = {
  textDecoration: 'none',
};

export const SidebarFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoWrapper = styled.div<Props>`
  display: flex;
  align-items: flex-end;
  padding-bottom: 40px;
  transition: all 0.3s;

  svg {
    transition: all 0.3s;
    display: block;
  }
`;

export const NavLinkWrapper = styled(NavLink)`
  display: block;
  border-radius: 5px;

  &:focus-visible {
    outline: 2px solid ${theme.colors.mtr60};
    outline-offset: 2px;
  }
`;

export const GradientOverlay = styled.div`
  @media ${device.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
`;
